<template>
    <div class="SetBox">


        <el-form ref="form" :model="value" label-width="80px">


            <el-col :span="9">
                <el-form-item label="SMTP地址" prop="title">
                    <el-input v-model="value.name" placeholder="请输入SMTP地址" />
                </el-form-item>

                <el-form-item label="SMTP端口" prop="title">
                    <el-input v-model="value.mail" placeholder="请输入SMTP端口" />
                </el-form-item>

                <el-form-item label="SMTP用户名" prop="title">
                    <el-input v-model="value.address" placeholder="请输入SMTP用户名" />
                </el-form-item>

                <el-form-item label="SMTP密码" prop="title">
                    <el-input v-model="value.phone" placeholder="请输入SMTP密码" />
                </el-form-item>


                <el-form-item label="启用SSL">
                    <el-radio v-model="value.radio" :label="1">启用</el-radio>
                    <el-radio v-model="value.radio" :label="2">停用</el-radio>
                </el-form-item>


                <el-form-item label="发件人" prop="title">
                    <el-input v-model="value.phone" placeholder="请输入发件人" />
                </el-form-item>


                <el-form-item>
                    <el-button type="primary" @click="onSubmit" size="mini">保存</el-button>
                </el-form-item>

            </el-col>

        </el-form>



    </div>
</template>

<script>
    export default {
        components: {

        },
        // model: {
        //   prop: 'value',
        //   event: 'change'
        // },
        // props: {
        //   value: {
        //     type: Object,
        //     required: true
        //   }
        // },
        data() {
            return {

                value: {
                    name: "",
                    mail: "",
                    address: "",
                    phone: "",
                    radio: 1,
                },

            }
        },
        methods: {
            onSubmit() {
                console.log('submit!');
            }

        }

    }
</script>

<style>
    .SetBox .el-form-item__label {

        /*    text-align: right;
        vertical-align: middle;
        float: left;
        font-size: 14px;
        color: #606266;
        line-height: 40px;
        box-sizing: border-box;
    */
        padding: 0 0px 0 0 !important;
        /*    border: 1px red dotted;
    box-sizing: border-box; */
        width: 100px !important;
    }

    .SetBox .el-form-item__content {

        margin-left: 110px !important;

    }
</style>
